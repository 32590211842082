import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/global/SEO'
import { Section } from '../components/global/Section';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Section>
      <h1 className="text-center mb-4">NOT FOUND</h1>
      <p className="text-center text-gray-400">You just hit a page that doesn&#39;t exist... the sadness.</p>
    </Section>
  </Layout>
)

export default NotFoundPage
